import Header from './components/Header'
import BurgerNav from './components/BurgerNav'
import Home from './pages/Home'
import Projects from './pages/Projects'
import Project from './pages/Project'
import Commissions from './pages/Commissions'
import Explore from './pages/Explore'
import ExploreItems from './pages/ExploreItems'
import Footer from './components/Footer'
import Bio from './pages/Bio'
import Intro from './pages/Intro'
import About from './pages/About'
import Narrative from './pages/Narrative'
import CV from './pages/CV'
import Contact from './pages/Contact'
import { BrowserRouter, Route, Routes} from 'react-router-dom'

function App() {
  return (
    <BrowserRouter>
      <BurgerNav />
      <Header />
      
      <Routes>
        <Route element={<Home />} path="/" exact="true"/>
        <Route element={<Project/>} path="/projects/:slug"/>
        <Route element={<Projects />} path="/projects" />
        <Route element={<Bio />} path="/bio" />
        <Route element={<Intro />} path="/intro" />
        <Route element={<About />} path="/about" />
        <Route element={<Narrative />} path="/narrative" />
        <Route element={<CV />} path="/cv" />
        <Route element={<Contact />} path="/contact" />
        <Route element={<Commissions />} path="/commissions" />
        <Route element={<ExploreItems />} path="/explore/:slug" />
        <Route element={<Explore />} path="/explore" />
      </Routes>
      <Footer text={`© 2024 Keith McIntyre. All rights reserved.`}/>
    </BrowserRouter>
  );
}

export default App;
