import React, {useState, useEffect} from 'react'
import {client} from '../client'
import Spinner from '../components/Spinner'


const Intro = () => {

    const [bio, setBio] = useState(null)

    useEffect(()=> {
        client.fetch(
            `*[_type == "bio"]{
               
        
                longtext,
           
                secondImage{
                    asset->{
                        _id,
                        url
                    },
                },
            }`
        )
        .then((data)=> setBio(data[0]))
    },[])

    const formatLongText = (string) => {
        let arr = string.split("#")
        return arr
    }


    if(!bio) return <Spinner />
  return (
    <main className="min-h-screen bg-gray-100 pb-24 sm:mb-0 2xl:mx-64 xl:mx-32 md:mx-16">
           

        <section className="mt-8 sm:mt-0">
            <div className="py-4 sm:mx-56">
                <h4 className="font-bold 2xl:text-4xl xl:text-2xl lg:text-3xl py-2 sm:py-8 sm:mb-4 md:text-xl sm:text-base sm:pb-4">The website as a Line of Desire.</h4>

                <img src={bio.secondImage.asset.url} alt="" className="w-full"/>
            </div>
            <div className="leading-8 px-2 sm:px-0">
                <div className="py-4 sm:mx-56">
                {
                    bio.longtext && formatLongText(bio.longtext).map((item, index)=>(
                        <div key={index} className="py-2">
                            <p className="2xl:py-2  2xl:text-lg 2xl:leading-8 xl:py-0 xl:leading-6 xl:text-base lg:text-lg md:text-base md:py-2 text-left">{item}</p>
                            
                        </div>
                    ))
                }
                </div>
            
            </div>
        </section>
       
    </main>
  )
}

export default Intro