import React from 'react'

const Spinner = () => {
  return (
    <div className="flex justify-center items-center content-center h-screen">
    <img src="./spinning-loading.gif" alt="" />
    </div>
  )
}

export default Spinner