import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom';
import {client} from '../client'
import Spinner from '../components/Spinner'

const Projects = () => {

    const [projects, setProjects] = useState(null)

    useEffect(()=> {
        client.fetch(
            `*[_type=="project"]{
                title,
                subtitle,
                slug,
                mainImage{
                    asset->{
                        _id,
                        url
                    },
                },
            }`
        )
        .then((data)=> setProjects(data))
        .catch(console.error)
    },[])

    if(!projects) return <Spinner />
  return (
    <main className="min-h-screen bg-gray-100 sm:pb-24">
    <section className="sm:mx-12 mx-2">
        <header className="sm:pt-8 pt-16 px-2 md:flex">
          <h2 className="text-3xl md:pr-8 sm:pb-2">Projects</h2>
        </header>
       
        <div className="grid grid-cols-1 2xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-16 sm:p-2 sm:p-12 pb-24 sm:pb-0">
            {projects && projects.map((item, index)=> (
                
                <div className="h-full " key={index}>
                    <div className="w-full h-full sm:hover:brightness-50 transition ease-in-out duration-125 object-cover w-full relative" >
                        <Link to={item.slug.current ? "/projects/"+item.slug.current : "/projects/"} key={item.slug.current}>

                            
                            <img src={item.mainImage.asset.url} alt={item.title} className="object-cover w-full 2xl:h-96 xl:h-80 lg:h-72 md:h-72 sm:h-96 sm:pb-8"/>
                            <div className="sm:border-t-2 sm:border-gray-400 sm:pb-0 pb-4 border-b border-gray-800 sm:border-b-0">
                            <p className="py-4 px-2 sm:text-2xl text-lg">{item.title}</p>
                            <p className="px-2 sm:text-xl text-md">{item.subtitle}</p>
                            </div>
                        
                        </Link>
                    </div>
                </div>


            ))}

        </div>
    </section>

 




</main>
  )
}

export default Projects