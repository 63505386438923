import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom';
import Spinner from '../components/Spinner'
import {client} from '../client'
import CategoriesNavHorizontal from '../components/CategoriesNavHorizontal'


const Explore = () => {

    const [categories, setCategories] = useState(null)
    const [projects, setProjects] = useState(null)

    useEffect(()=> {
        client.fetch(
            `*[_type=="category"]{
                title,
                slug,
                mainImage{
                    asset->{
                        _id,
                        url
                    },
                },

            }`
        )
        .then((data)=> setCategories(data))
        .catch(console.error)
    },[])

    useEffect(()=> {
        client.fetch(
            `*[_type=="project"]{
                title,
                slug,
                mainImage{
                    asset->{
                        _id,
                        url
                    },
                },
            }`
        )
        .then((data)=> setProjects(data))
        .catch(console.error)
    },[])

 
    if(!categories || !projects) return <Spinner/>
  return (
    <main className="min-h-screen bg-gray-100 mb-0 sm:mb-0 pb-24">
        <div className="mx-2 sm:mx-12">
            <section className="max-w-screen">

                    <div className="flex inline-flex items-center max-w-screen">
                    <CategoriesNavHorizontal />
                    </div>
              
            
                <div>   
                   

                    <div className="sm:grid 2xl:grid-cols-3 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 sm:gap-8 ">
                        {categories && categories.map((cat, index)=> (
                            <div className="sm:hover:brightness-50 transition ease-in-out duration-125  sm:border-0 mb-4 sm:mb-0 pb-4 sm:pb-0 " key={index}>
                                <Link to={cat.slug ? "/explore/"+cat.slug.current : "/explore/"}>
                                    <div className="sm:w-auto 2xl:h-96 xl:h-80 lg:h-72 md:h-72 sm:h-96 sm:object-cover w-full sm:relative" key={index}>       
                                        <img src={cat.mainImage ? cat.mainImage.asset.url : null} alt={cat.title} className="sm:object-cover sm:absolute 2xl:h-96 xl:h-80 lg:h-72 md:h-72 sm:h-96 w-full transition duration-150 ease-in-out pb-2 sm:pb-8"/>         
                                    </div>
                                    <div className="sm:border-t-2 sm:border-gray-400 sm:pb-0 pb-4 border-b border-gray-800 sm:border-b-0">
                                    <p className="py-4 px-2 sm:text-2xl text-lg">{cat.title}</p>
                                    </div>

                                </Link>
                            </div>
                        ))} 
                    </div>          
                </div>                     
          <div>     
         </div>

        </section>
        </div>  
    </main>
  )
}

export default Explore