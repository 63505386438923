import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom';
import {client} from '../client'
import Spinner from '../components/Spinner'
import { useParams} from 'react-router-dom'
import Modal from '../components/Modal'
import { MdOutlineArrowBack } from "react-icons/md";


const ExploreItems = () => {

  const [data, setData] = useState(null)
  const { slug } = useParams()

  const [showModal, setShowModal] = useState(false)
  const [currentIndex, setCurrentIndex] = useState(0)

  const filterString = (string) => {
    const regex = /-/i;
    const newString = string.replace(regex, " ");
    const words = newString.split(" ");
    for (let i = 0; i < words.length; i++) {
      words[i] = words[i][0].toUpperCase() + words[i].substr(1);
    }
    return words.join(" ")
  }

  const newSlug = filterString(slug)


  useEffect(()=> {
    client.fetch(
      `*[_type=="category" && slug.current == "${slug}"]{
         title,
         _id,
         description,
         "photos": *[_type == "photo" && references(^._id)] | order(lower(title) asc){
          title,
          slug,
          alt,
          mainImage{
              asset->{
                  _id,
                  url
              },
          },
          relatedProject->{slug, title}
         }
      }`
  )
  .then((data)=> setData(data[0]))
  .catch(console.error)
  },[slug])

  const handleModalClick = (e, index) => {
    e.preventDefault()
    setShowModal(true)
    setCurrentIndex(index)
}


  if(!data) return <Spinner />
  return (

    <main className="min-h-screen bg-gray-100 mb-20 sm:mb-0 2xl:mx-64 xl:mx-32 md:mx-16">
        {
            showModal ? (<Modal setShowModal={setShowModal} props={data.photos} currentIndex={currentIndex} setCurrentIndex={setCurrentIndex}/>) : null
        }
   <div className="mx-2 sm:mx-12">
      <section className="max-w-screen mt-8 sm:mt-0">
                
                    <nav className="pt-4">
                    <Link to="/explore"><div className="flex position items-center text-md hover:underline cursor:pointer"> <MdOutlineArrowBack /><button className="pl-2">Explore</button></div> </Link> 
                    </nav>

        <div className="py-4 sm:mx-56 leading-8">
          <h2 className="text-3xl sm:mb-2">{newSlug}</h2>
          <p className="2xl:py-2  2xl:text-lg 2xl:leading-8 xl:py-0 xl:leading-6 xl:text-base lg:text-lg md:text-base md:py-2 text-left">{data.description ? data.description : ""}</p>
        </div>

        <div className="py-4 sm:mx-56">
          {data && data.photos.map((item, index)=>(
            <div key={index} onClick={(e)=> handleModalClick(e, index)}>
              <Link to="#">
              <img src={item.mainImage ? item.mainImage.asset.url : null} alt={item.alt} className={`w-full py-4`}/>
              </Link>
            </div>
          ))}
        </div>
    

    </section>



    </div>

    
</main>
 
  )
}

export default ExploreItems