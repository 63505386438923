import React, {useState} from 'react'
import { NavLink } from 'react-router-dom'
import { GiHamburgerMenu } from "react-icons/gi";
import { ImCross } from "react-icons/im";


const BurgerNav = () => {

    const [showMobileNav, setShowMobileNav] = useState(false)

    const burger = () => {
        setShowMobileNav(!showMobileNav)
    }

    const closeOnNavClick = () => {
        setShowMobileNav(false)
    }

  return (
    <nav className="bg-white border-b border-slate-400 sm:hidden w-screen sm:w-full absolute z-10 ">
        <button className="flex p-1 transition ease-in-out delay-150 inline-flex items-center" onClick={burger}>
            {
                showMobileNav ? <><ImCross className="p-1"/> <h6 className="pl-2">MENU</h6></>
                :
                <><GiHamburgerMenu /> <div className="flex justify-between"><h6 className="pl-2">MENU</h6><h2 className="font-bold pl-8 ">KEITH MCINTYRE STUDIO</h2></div></>
            }
            
            </button>
        
        {
            showMobileNav ? 
            <div className="bg-white container w-auto px-8 transition ease-in-out delay-150 w-32">
         <ul className="flex flex-col underline">
        <NavLink to="/" exact="true"  className="inline-flex items-end px-3 py-2 rounded text-gray-800 hover:underline" onClick={closeOnNavClick}>
        <li>Home</li>
        </NavLink>
        <NavLink to="/bio"  className="inline-flex items-end px-3 py-2  rounded text-gray-800 hover:underline" onClick={closeOnNavClick}>
        <li>Intro</li>
        </NavLink>
        <NavLink to="/explore"  className="inline-flex items-end px-3 py-2  rounded text-gray-800 hover:underline" onClick={closeOnNavClick}>
        <li>Explore</li>
        </NavLink>
        <NavLink to="/projects"  className="inline-flex items-end px-3 py-2 rounded text-gray-800 hover:underline" onClick={closeOnNavClick}>
        <li>Projects</li>
        </NavLink>
        <NavLink to="/about"  className="inline-flex items-end px-3 py-2 rounded text-gray-800 hover:underline" onClick={closeOnNavClick}>
        <li>About</li>
        </NavLink>
      
    
       
        </ul>
    </div> : <div></div>
        }
    
    </nav>
  )
}

export default BurgerNav