import React, {useState, useEffect} from 'react'
import {client} from '../client'
import Spinner from '../components/Spinner'
import AboutNav from '../components/AboutNav'

const Narrative = () => {

    const [about, setAbout] = useState(null)

    useEffect(()=> {
        client.fetch(
            `*[_type == "about"]{
                header,
                subtitle, 
                image1{
                    asset->{
                        _id,
                        url
                    },
                },
                paragraph1,
                image2{
                    asset->{
                        _id,
                        url
                    },
                },
                paragraph2,
                image3{
                    asset->{
                        _id,
                        url
                    },
                },
                paragraph3,
                image4{
                    asset->{
                        _id,
                        url
                    },
                },
                paragraph4,
              
              
            }`
        )
        .then((data)=> setAbout(data[0]))
    },[])

    const formatLongText = (string) => {
        let arr = string.split("#")
        return arr
    }

    if(!about) return <Spinner />
  return (
    <main className="min-h-screen bg-gray-100 mb-20 sm:mb-0 px-2 sm:px-0">

    <div className="sm:grid sm:grid-cols-5 sm:mx-12">
        <AboutNav/>
       <div className="sm:col-span-4 sm:mt-8">

    <header className="sm:px-24 mx-4">
        <h2 className="font-bold 2xl:text-4xl xl:text-2xl lg:text-3xl pt-4 md:text-xl sm:text-base">{about.header}</h2>
        <p className="2xl:py-6 xl:py-4 lg:py-8  md:py-4 xl:text-xl lg:text-xl">{about.subtitle}</p>
    </header>

    <section className="sm:mx-56 py-12"  >
       
       { about.image1 ? <div className="flex justify-center items-center py-8">
        <img src={about.image1.asset.url} alt="" className=""/>
        </div> : null}

       { about.paragraph1 ? <div className="sm:mt-8  py-4 max-h-full text-center xl:text-left">
            <div className="2xl:py-4  2xl:text-lg 2xl:leading-8 xl:py-8 xl:leading-6 xl:text-base lg:text-lg md:text-base md:py-2 text-left">
                {
                    about.paragraph1 && formatLongText(about.paragraph1).map((item, index)=>(
                        <div className="py-2" key={index}>
                            <p>{item}</p>  
                        </div>
                    ))
                }
            </div>
        </div> : null}
    </section>

    <section className="sm:mx-56 py-12" >
       
       { about.image2 ? <div className="flex justify-center items-center py-8">
        <img src={about.image2.asset.url} alt="" className=""/>
        </div> : null}

       { about.paragraph2 ? <div className="sm:mt-8 py-4 max-h-full text-center xl:text-left ">
            <div className="2xl:py-4  2xl:text-lg 2xl:leading-8 xl:py-0 xl:leading-6 xl:text-base lg:text-lg md:text-base md:py-2 text-left">
                {
                    about.paragraph2 && formatLongText(about.paragraph2).map((item, index)=>(
                        <div className="py-2" key={index}>
                            <p>{item}</p>  
                        </div>
                    ))
                }
            </div>
        </div> : null}
    </section>

    <section className="sm:mx-56 py-12">
       
       { about.image3 ? <div className="flex justify-center items-center py-8">
        <img src={about.image3.asset.url} alt="" className=""/>
        </div> : null}

       { about.paragraph3 ? <div className="sm:mt-8 py-4 max-h-full text-center xl:text-left ">
            <div className="2xl:py-4  2xl:text-lg 2xl:leading-8 xl:py-0 xl:leading-6 xl:text-base lg:text-lg md:text-base md:py-2 text-left">
            {
                    about.paragraph3 && formatLongText(about.paragraph3).map((item, index)=>(
                        <div className="py-2" key={index}>
                            <p>{item}</p>  
                        </div>
                    ))
                }
            </div>
        </div> : null}
    </section>

    <section className="sm:mx-56 py-12" >
       
       { about.image4 ? <div className="flex justify-center items-center py-8">
        <img src={about.image4.asset.url} alt="" className=""/>
        </div> : null}

       { about.paragraph4 ? <div className="sm:mt-8 py-4 max-h-full text-center xl:text-left ">
            <div className="2xl:py-4  2xl:text-lg 2xl:leading-8 xl:py-0 xl:leading-6 xl:text-base lg:text-lg md:text-base md:py-2 text-left">
            {
                    about.paragraph4 && formatLongText(about.paragraph4).map((item, index)=>(
                        <div className="py-2" key={index}>
                            <p>{item}</p>  
                        </div>
                    ))
                }
            </div>
        </div> : null}
    </section>

    </div> 
    </div> 
   
</main>
  )
}

export default Narrative