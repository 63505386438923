import React, {useState, useEffect} from 'react'
import {client} from '../client'
import { useParams } from 'react-router-dom'
import Spinner from '../components/Spinner'
import Modal from '../components/Modal'
import Grid from '../components/Grid'

const Project = () => {

    const [project, setProject] = useState(null)
    const { slug } = useParams();

    const [showModal, setShowModal] = useState(false)
    const [currentIndex, setCurrentIndex] = useState(0)

    useEffect(() => {
        client.fetch(`*[_type == "project" && slug.current == "${slug}"]{
            title,
            subtitle,
            description,
            year,
            institution,
            videoEmbed->{url},
            mainImage{
                asset->{
                    _id,
                    url
                }
            },
            "images": images[]->{
                title,
                slug,
                attribution,
                description, 
                mainImage{
                    asset->{
                        _id,
                        url
                    }
                },
                alt,
                
            }
        }`)
        .then((data) => setProject(data[0]))
        .catch(console.error)
    },[slug]);

    const videoRender = () => {
        if(!project.videoEmbed){
            return null
        }else{
        return <div className="mx-auto w-auto">
     
         <iframe src={project.videoEmbed.url} id="vimeo" title='vimeo' width='640' height='360' frameBorder='0' allow='autoplay; fullscreen; picture-in-picture' allowFullScreen></iframe>
        </div>
        }
    }

      const handleModalClick = (e, index) => {
        e.preventDefault()
        setShowModal(true)
        setCurrentIndex(index)
    }

    if(!project) return <Spinner />
  return (
    <main className={`min-h-screen bg-gray-100 pb-24 sm:mb-0 pt-16 sm:pt-0`}>
        {
            showModal ? (<Modal setShowModal={setShowModal} props={project.images} currentIndex={currentIndex} setCurrentIndex={setCurrentIndex}/>) : null
        }
        <section className="p-2 sm:p-12 sm:mx-48 h-auto flex justify-between flex flex-col justify-between ">

            <img src={project.mainImage.asset.url} alt="" className=" sm:h-2/3 w-auto"/>

            <div className="flex justify-between sm:py-6 ">

                <div className="py-4 sm:mx-56">
                    <div className="2xl:py-2  2xl:text-lg 2xl:leading-8 xl:py-0 xl:leading-6 xl:text-base lg:text-lg md:text-base md:py-2 text-left">
                    <h2 className="sm:text-3xl text-lg italic">{project.title}</h2>
                    <p className="sm:text-xl pt-2">{project.description}</p>
                    </div> 
                
                    <div className="2xl:py-2  2xl:text-lg 2xl:leading-8 xl:py-0 xl:leading-6 xl:text-base lg:text-lg md:text-base md:py-2 text-left"> 
                    <p className="sm:text-xl py-2 font-bold">{project.year}</p>
                    <p className="sm:text-xl italic">{project.institution}</p>
                    </div>
                        
                </div> 

            </div>

        </section>

        {
            project.videoEmbed? 
        (<section className="p-2 sm:p-12 sm:mx-48 h-screen flex justify-between flex flex-col justify-between">
            {videoRender()}
        </section>)
        : (
            <div></div>
        )
        }

        <Grid photos={project.images} handleModalClick={handleModalClick}/>


    </main>
  )
}

export default Project

