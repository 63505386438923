import React from 'react'
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from 'react-router-dom';



const Modal = ({setShowModal, props, currentIndex, setCurrentIndex}) => {

    const forward = () => {
        const index = currentIndex +=1;
        if(index < props.length){
            setCurrentIndex(index)
        }

    }

    const back = () => {
        const index = currentIndex -=1;
        if(index >= 0) {
            setCurrentIndex(index)
        }
    }

  return (
    <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-opacity-75 bg-gray-700 transition-opacity ">
                
                <div className="invisible sm:visible" >
                    <button className="text-white absolute left-0 pl-24 text-5xl" onClick={back}><IoIosArrowBack /></button>
                </div>

            <div className="relative transform sm:w-2/3 my-4 mx-auto max-h-screen transition-all">
              <div className="border-0  shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none ">
                
                <div className="flex items-start justify-between px-4 py-2  ">
                  <h3 className="text-2xl font=semibold">{props[currentIndex].title}</h3>
                  <button
                    className="bg-transparent border-0 text-black float-right"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="text-black opacity-7 h-6 w-6 text-2xl block py-0 ">
                      x
                    </span>
                  </button>
                </div>

                <div className="relative p-4 flex-auto ">
                <img src={props[currentIndex].mainImage.asset.url} alt="" className="w-full"/>
                </div>
                
                <div className="relative p-4 flex-auto leading-6">
                    <p>{props[currentIndex].description ? props[currentIndex].description : ""}</p>
                    <p>{props[currentIndex].attribution ? props[currentIndex].attribution : ""}</p>
                    <p>{props[currentIndex].relatedProject ? (<p className="sm:hover:underline "><Link to={props[currentIndex].relatedProject.slug}>{"Related Project: " + props[currentIndex].relatedProject.title}</Link></p>) : ""}</p>
                </div>

                <div className="flex items-center justify-end  ">
                  <button
                    className="text-gray-700 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button>

                </div>
              </div>
            </div>
                <div className="invisible sm:visible">
                    <button className="text-white absolute right-0 pr-24 text-5xl" onClick={forward}>
                    <IoIosArrowForward />
                    </button>
                </div>
          </div>
  )
}

export default Modal
