import React from 'react'
import { Link } from 'react-router-dom';

const AboutNav = () => {
  return (
    <div>
        <nav className="sm:col-span-1 sm:m-8 mb-4 text-sm sm:text-sm md:text-base">
        <h4 className="text-xl mx-4 py-2">About:</h4>
            <ul className="sm:py-0 sm:w-48 flex sm:flex-col justify-between mx-4">
                <Link to="/narrative"><li className="py-2 underline" >CV Narrative</li></Link>
                <Link to="/cv"><li className="py-2 underline" >CV Chronological</li></Link>
            </ul>
            <ul className="sm:py-0 sm:w-48 flex sm:flex-col justify-between mx-4">
                <Link to="/commissions"><li className="py-2 underline" >Commissions, Sales and Studio Visits</li></Link>
                <Link to="/contact"><li className="py-2 underline" >Contact</li></Link>
            </ul>
        </nav>
    </div>
  )
}

export default AboutNav