import React from 'react'
import uglysister from '../assets/ugly-sister.jpg'
import AboutNav from '../components/AboutNav'

const About = () => {

   
  return (
    
    <main className="min-h-screen bg-gray-100 pb-24 sm:mb-0 mx-0 px-2 sm:px-0">
       
        <div className="sm:grid sm:grid-cols-5 sm:mx-12 ">
        <AboutNav/>
        <section className="sm:col-span-4 sm:mt-8">
          <div className="mx-0 sm:mx-56 py-1 ">
          <img src={uglysister} alt="" className="w-full sm:w-full md:w-full xl:w-full 2xl:w-2/3 mx-auto" />
          </div>
            
        </section>
        </div>
   
       
    </main>
  )
}

export default About