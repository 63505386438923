import React, {useState, useEffect} from 'react'
import {client} from '../client'
import Spinner from '../components/Spinner'
import AboutNav from '../components/AboutNav'

const Contact = () => {

    const [contact, setContact] = useState(null)

    useEffect(()=> {
        client.fetch(
            `*[_type == "contact"]{
                header, 
                email, 
                phone,
                studioVisit,
                salesInfo,
                contactImage{
                    asset->{
                        _id,
                        url
                    },
                },
              
              
            }`
        )
        .then((data)=> setContact(data[0]))
    },[])


    if(!contact) return <Spinner />
  return (
    <main className="min-h-screen bg-gray-100 mb-20 sm:mb-0">

        <div className="sm:grid sm:grid-cols-5 sm:mx-12">
            <AboutNav/>

            <section className="sm:col-span-4 sm:mt-8">
                <div className="sm:flex bg-white">
                    <div  className="sm:w-1/2">
                        <img src={contact.contactImage.asset.url} alt="" />
                    </div>

                    <div className="sm:w-1/2 p-8"> 
                    <p className="py-2">{contact.header}</p>
                    <p className="py-2">Email: {contact.email}</p>
                    <p className="py-2">Telephone: {contact.phone}</p>
           
                    </div>
                
                   
                </div>
            </section>
        </div>
    </main>
  )
}

export default Contact