import React, {useState, useEffect} from 'react'
import {client} from '../client'
import Spinner from '../components/Spinner'
import {Link} from 'react-router-dom'


const Bio = () => {

    const [bio, setBio] = useState(null)

    useEffect(()=> {
        client.fetch(
            `*[_type == "bio"]{
                header,
                subtitle, 
                bio,
                mainImage{
                    asset->{
                        _id,
                        url
                    },
                },
             
            }`
        )
        .then((data)=> setBio(data[0]))
    },[])



    if(!bio) return <Spinner />
  return (
    <main className="min-h-screen bg-gray-100 mb-20 sm:mb-0 2xl:mx-64 xl:mx-32 md:mx-16">
           

<section className="grid-cols-1" >
           
           <div className="h-full">
           
           <img src={bio.mainImage.asset.url} alt="" />
           </div>

           <div  className="leading-8 px-2 sm:px-0">
               <div className="py-4 sm:mx-56">
               <div className="flex font-bold 2xl:text-4xl xl:text-2xl lg:text-3xl pt-4 md:text-xl sm:text-base py-4 ">
                <h2 >{bio.header} -  </h2>
                <p className="pl-2"> { bio.subtitle} </p>
                </div>
                <p className="2xl:py-8  2xl:text-lg 2xl:leading-8 xl:py-0 xl:leading-6 xl:text-base lg:text-lg md:text-base md:py-2 text-left">
                    {bio.bio}
                    </p>
                    <Link to="/intro"><div className="py-4 flex position items-center text-md hover:underline cursor:pointer"><button className="text-xl underline">About the site as a Line of Desire</button></div> </Link> 

               </div>
               
           </div>
       </section>
       
    </main>
  )
}

export default Bio