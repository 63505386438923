import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom';

import {client} from '../client'

const CategoriesNavHorizontal = () => {


    const [categories, setCategories] = useState(null)

    useEffect(()=> {
        client.fetch(
            `*[_type == "category"]{
                title,
                slug,
                "categories": categories[]->{
                    title
                }
              
            }`
        )
        .then((data)=> setCategories(data))
        .catch(console.error)
    },[])


  return (
    <nav className="my-8 sm:my-4">
    <ul className="pt-4 pb-2 grid-rows-2">
        {categories && categories.map((category, index)=> (
            
            <li className=" flex inline-flex items-end w-auto h-auto p-1 mb-1 sm:px-2 sm:py-2 mr-1 sm:mr-2 sm:mb-2 hover:underline border border-black" key={index}>
                <Link to={category.slug ? "/explore/"+category.slug.current : "/explore/"}>
                <h6 className="sm:text-base text-xs text-nowrap w-auto">{category.title}</h6>
                </Link>
            </li>
            
        ))}
     
    </ul>
    </nav>
  )
}

export default CategoriesNavHorizontal