import React, {useState, useEffect} from 'react'
import {client} from '../client'
import Spinner from '../components/Spinner'
import AboutNav from '../components/AboutNav'

const CV = () => {

    const [cv, setCV] = useState(null)
    const [years, setYears] = useState(null)
    const [sortedCV, setSortedCV] = useState(null)

    useEffect(()=> {
        client.fetch(
            `*[_type == "cvitem"] | order(year asc){
                title,
                subCategory, 
                cvCategory,
                year,
                institution,
                funder, 
                link, 
                description,
                image1{
                    asset->{
                        _id,
                        url
                    },
                },
            
              
              
            }`
        )
        .then((data)=> setCV(data))
    },[])

 
    useEffect(()=>{

        const getYears = () => {
            let arr = []
            for (let i = 0; i < cv.length; i++){
                arr.push(cv[i].year)
            }
            const noDuplicates = [...new Set(arr)];
            setYears(noDuplicates)
        }

        cv && getYears()
    },[cv])



    useEffect(()=> {
        const sortIntoYears = () => {
            let yearsArr = [];
            for (let i = 0; i < years.length; i++){
                let year = years[i];
                let yearArr = []
                for (let j = 0; j < cv.length; j ++){   
                    if(cv[j].year === year){
                        yearArr.push(cv[j])
                    }
                }
                yearsArr.push(yearArr); 
            }
            return yearsArr
        }

        const arr = years && sortIntoYears();
        years && setSortedCV(arr)
    },[years, cv])

    console.log(cv)
    console.log(years)
    console.log(sortedCV)

    if(!cv) return <Spinner />
  return (
    <main className="min-h-screen bg-gray-100 pb-24">

    <div className="sm:grid sm:grid-cols-5 sm:mx-12">
           <AboutNav /> 
           


    <section className="sm:col-span-4 sm:mt-8 mx-4 mb-20 sm:mb-0">
            {sortedCV && sortedCV.map((item, i)=>(
               <div key={i} className="sm:mx-24 md:mx-36 lg:mx-56 py-1 ">
                <p className="font-bold py-2">{item[0].year}</p>
                {
                item.map((subItem, j)=>(
                    <div key={j} className="">
                        <p>- {subItem.title}</p>
                    </div>
                ))
                }
               </div>
            ))}
    </section>
    </div>
    </main>
  )
}

export default CV