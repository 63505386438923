import React from 'react'
import { NavLink } from 'react-router-dom'

const Header = () => {
  return (
    <header className="bg-white border-b border-slate-400 invisible h-0 sm:visible sm:h-auto z-0">
        
        <div className="bg-white container mx-auto flex justify-between px-8">
    
    <ul className="flex">
        <NavLink to="/" exact="true"  className="inline-flex items-end px-3 py-2 my-4 rounded text-gray-800 hover:underline">
        <li className="font-bold">KEITH MCINTYRE STUDIO</li>
        </NavLink>
        <NavLink to="/bio"  className="inline-flex items-end px-3 py-2 my-4 rounded text-gray-800 hover:underline">
        <li>Intro</li>
        </NavLink>
   
        <NavLink to="/explore"  className="inline-flex items-end px-3 py-2 my-4 rounded text-gray-800 hover:underline">
        <li>Explore</li>
        </NavLink>

        <NavLink to="/projects"  className="inline-flex items-end px-3 py-2 my-4 rounded text-gray-800 hover:underline">
        <li>Projects</li>
        </NavLink>

        <NavLink to="/about"  className="inline-flex items-end px-3 py-2 my-4 rounded text-gray-800 hover:underline">
        <li>About</li>
        </NavLink>

    </ul>
   
    </div>
    </header>
  )
}

export default Header