import React from 'react'

const Footer = ({text}) => {
  return (
    <footer className="w-full">
        <div className="h-32 flex items-center">
            <p className="mx-auto 2xl:py-2  2xl:text-lg 2xl:leading-8 xl:py-0 xl:leading-6 xl:text-base lg:text-lg md:text-base md:py-2 text-left text-gray-400">{text}</p>

        </div>
    </footer>
  )
}

export default Footer