import React, {useState, useEffect} from 'react'
import {client} from '../client'
import Spinner from '../components/Spinner'
import AboutNav from '../components/AboutNav'

const Commissions = () => {

    const [commissions, setCommissions] = useState(null)

    useEffect(()=> {
        client.fetch(
            `*[_type == "commissions"]{
                title, 
                general, 
                commissionsInfo,
                salesInfo,
                studioInfo,
                commissionsImage{
                    asset->{
                        _id,
                        url
                    },
                },
              
              
            }`
        )
        .then((data)=> setCommissions(data[0]))
    },[])

    console.log(commissions)

    if(!commissions) return <Spinner />
  return (
    <main className="min-h-screen bg-gray-100 mb-20 sm:mb-0">

        <div className="sm:grid sm:grid-cols-5 sm:mx-12">
            <AboutNav/>

            <section className="sm:col-span-4 sm:mt-8">
                <div className="sm:flex bg-white">
                    <div  className="sm:w-1/2">
                    {/* <p>{commissions.title}</p> */}
                       { commissions.commissionsImage ? <img src={commissions.commissionsImage.asset.url} alt="" /> : null}
                    </div>

                    <div className="sm:w-1/2 p-8"> 
                    
                    <p className="py-2">{commissions.General}</p>
                    <p className="py-2">{commissions.commissionsInfo}</p>
                    <p className="py-2">{commissions.salesInfo}</p>
                    <p className="py-2">{commissions.studioInfo}</p>
                    </div>
                
                   
                </div>
            </section>
        </div>
    </main>
  )
}

export default Commissions