import React from 'react'

const Grid = ({photos, handleModalClick}) => {

    const length = photos && photos.length

if (length === 0) return <div>No images available</div>

  return (
    <section className="grid grid-flow-row grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-4 sm:p-12 justify-items-center">
    <div>
    {photos && photos.slice(0, length/2).map((photo, index) => (
           <div className= "cursor-pointer hover:brightness-50 transition ease-in-out duration-125 p-8" key={index} onClick={(e)=> handleModalClick(e, index)}>
               <img src={photo.mainImage ? photo.mainImage.asset.url : "/db-error.png"} alt={photo ? photo.alt : "no image"} className="w-full "/>
               
           </div>
       ))
       }
    </div>
    <div>
    {photos && photos.slice(length/2, length).map((photo, index) => (
           <div className= "cursor-pointer hover:brightness-50 transition ease-in-out duration-125 p-8" key={index} onClick={(e)=> handleModalClick(e, index + (Math.floor(length/2)))}>
               <img src={photo.mainImage ? photo.mainImage.asset.url : "/db-error.png"} alt={photo ? photo.alt : "no image"} className="w-full"/>
           </div>
       ))
       }
    </div>

   </section>
  )
}

export default Grid