import React, {useState, useEffect} from 'react'
import {client} from '../client'
import Spinner from '../components/Spinner'
import Footer from '../components/Footer'
import elephant from '../assets/white-elephant.jpg'

const Home = () => {

    const [homeData, setHomeData] = useState(null)

    useEffect(()=> {
        client.fetch(
            `*[_type == "homepage"]{
                header,
                subtitle,
                mainImage{
                    asset->{
                        _id,
                        url
                    },
                },
            }`
        )
        .then((data) => setHomeData(data[0]))
        .catch(console.error)
    },[])

    if(!homeData) return <Spinner />
  return (
    <main className="h-screen bg-white mb-20 sm:mb-0 ">
   
        <section className="h-full">
            <div className="flex justify-center items-center h-full">
                <img src={homeData.mainImage ? homeData.mainImage.asset.url : elephant} alt={homeData.title} className="sm:mx-auto sm:h-full"/>
            </div>
            {
            homeData.subtitle ? <Footer text={homeData.subtitle}/> : <></>
        }
        </section>
       
        
    </main>
  )
}

export default Home